  .link {
      margin: 2px !important;
      margin-left: unset !important;
      margin-right: 5px !important;
  }

  .avatar {
      margin: 5px;
      width: 84px !important;
     height: 84px !important;
      
  }

  .online {
      border: 3px solid #77F73B;
  }

  .status {
      border: 3px solid #f7513b;
  }