.StreamBlock{
    box-sizing: border-box;
    width: 100%;
    position: relative;
}

/* .VideoScreen {
  object-fit: fill;
} */


.bottomChat {
  display: none;
}

/* .Video {
    margin: 0 10px 0 0;
    width: 100%;
    width: calc(50% - 7px);
    border: 2px solid red
  }
  
.Video:last-of-type {
    margin-right: 0;
  } */

  .btnFullScrean {
    position: absolute !important;
    z-index: 22 !important;
    bottom: 10px !important;
    right: 10px !important;
    border: none !important;
    background: #000000 !important;
    transition: 0.2s ease !important;
  }

  .btnSound {
    position: absolute !important;
    z-index: 22 !important;
    bottom: 10px !important;
    left: 10px !important;
    border: none !important;
    background: #000000 !important;
    transition: 0.2s ease !important;
  }
  .btnFullScrean:hover {
    background: #353535 !important;
    transition: 0.2s ease !important;
  }

  .btnFavouritesSaccess {
    position: absolute !important;
    z-index: 22 !important;
    top: 10px !important;
    right: 10px !important;
    border: none !important;
    background: #ED2779 !important;
    transition: 0.2s ease !important;
    color: #ffffff !important;
    font-size: 12px !important;
  }

  .btnFavouritesDestroy {
    position: absolute !important;
    z-index: 22 !important;
    top: 10px !important;
    right: 10px !important;
    border: none !important;
    background: #a40a4a !important;
    transition: 0.2s ease !important;
    color: #020202 !important;
    font-size: 12px !important;
  }

  .liveStatus {
    position: absolute !important;
    z-index: 22 !important;
    top: 10px !important;
    left: 10px !important;
    border: none !important;
    transition: 0.2s ease !important;
    font-size: 14px !important;
    text-transform: uppercase;
}


  @media screen and (max-width: 768px) {
    .btnFullScrean {
        right: 5px !important;
        bottom: 5px !important;
    }

    .btnFavourites {
      top: 5px !important;
      right: 5px !important;
      transition: 0.2s ease !important;
    }
    
    .StreamBlock{
      box-sizing: border-box;
      width: 100%;
    }

    .bottomChat {
      display: block;
    }
}


  @media screen and (max-width: 480px) {
    .btnFullScrean {
        right: 5px !important;
        bottom: 5px !important;
    }

    .liveIcon {
      font-size: 10px !important;
      color: #000000
    }

    .liveStatus {
      font-size: 10px !important;
    }

    .btnFavourites {
      top: 5px !important;
      right: 5px !important;
      transition: 0.2s ease !important;
      font-size: 9px !important;
    }
    
    .StreamBlock{
      box-sizing: border-box;
      width: 100%;
    }

    .bottomChat {
      display: block;
    }
}