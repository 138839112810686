$font-regular: "Effra Light", Arial, sans-serif;
$font-medium: "Effra Medium", Arial, sans-serif;
$font-bold: "Effra Bold", Arial, sans-serif;

@font-face {
    font-family: 'Effra Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Heavy'), url('../fonts/effra/Effra\ Heavy.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Effra Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Heavy Italic'), url('../fonts/effra/Effra\ Heavy\ Italic.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Effra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Light'), url('../fonts/effra/Effra\ Light.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Effra Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Medium'), url('../fonts/effra/Effra\ Medium.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Effra Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Italic'), url('../fonts/effra/Effra\ Italic.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Effra Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Light Italic'), url('../fonts/effra/Effra\ Light\ Italic.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Effra Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Medium Italic'), url('../fonts/effra/Effra\ Medium\ Italic.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Effra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Bold'), url('../fonts/effra/Effra\ Bold.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Effra Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Bold Italic'), url('../fonts/effra/Effra\ Bold\ Italic.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Thin';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/roboto/Roboto-Thin.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy Regular'), url('../fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy Medium'), url('../fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy Semibold'), url('../fonts/gilroy/Gilroy-Semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy Bold'), url('../fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
    font-display: swap;
}


$font-effra-bold: "Effra Bold";
$font-effra-medium: "Effra Medium";
$font-effra-regular: "Effra Regular";
$font-effra-italic: "Effra Italic";
$font-effra-heavy: "Effra Heavy";
$font-montserrat-bold: "montserratBold";
$font-montserrat-medium: "montserratMedium";
$font-montserrat-regular: "montserratRegular";
$font-montserrat-italic: "montserratItalic";
$font-montserrat-thin: "montserratThin";
$font-roboto-bold: "Roboto Bold";
$font-roboto-medium: "Roboto Medium";
$font-roboto-regular: "Roboto";
$font-roboto-thin: "Roboto Thin";
$font-gilroy-regular: "Gilroy Regular";
$font-gilroy-medium: "Gilroy Medium";
$font-gilroy-semibold: "Gilroy SemiBold";
$font-gilroy-bold: "Gilroy Bold";
$font-gilroy-black: "Gilroy Black";

body {
    font-family: $font-medium;
    font-size: $font-size;
}