:root{
    --primary-color: #77F73B;
    --secondary-color: #ffd800;
    --third-color: rgb(167, 37, 249);
    --font-color: #ffffff;
    --primary-bg-color: #292929;
    --secondary-bg-color: #121212;
    --dark-bg-color: rgb(10, 10, 10);
    --heading-color: #ffffff;
    --white-color: #ffffff;
    --black-color: #000000;
}

$font-size: 14px;
$font-size-sm: 12px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xlg: 30px;
$font-size-xxlg: 42px;
$font-size-xxxlg: 60px;

$transition: .1s;