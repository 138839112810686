.input{
    width: 100%;
    background: rgb(29, 29, 29);
    border-radius: 5px;
    padding: 6px 15px;
    font-size: 16px;
    font-family: "Roboto" !important;
    min-width: 335px;
}

.inputSm{
    background: rgb(29, 29, 29);
    border-radius: 5px;
    font-size: 16px;
    font-family: "Roboto" !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.inputSm input{
    padding: 4px 9px !important;
}
