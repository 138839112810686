@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
body.no-scroll {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
}
deo-video,
deo-hub {
  line-height: 16px;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  display: block;
  position: relative;
  background: black;
  z-index: 50;
  overflow: hidden;
  color: white;
  font-family: "Roboto", sans-serif;
}
deo-video li,
deo-hub li {
  display: none;
}
deo-video *,
deo-hub * {
  pointer-events: inherit;
}
deo-preplay {
  position: absolute;
  z-index: 110;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
deo-preplay * {
  pointer-events: all;
}
.deo--running deo-preplay {
  display: none;
}
.deo--maximized {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  height: auto !important;
  width: auto !important;
}
.deo-container {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.deo-container canvas {
  position: relative;
  z-index: 70;
  cursor: -webkit-grab;
  cursor: grab;
}
.deo-container canvas.pointing {
  cursor: pointer;
}
.deo-cover {
  position: absolute;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #111;
}
.deo-cover .deo-deeplink {
  right: 8px !important;
  top: 8px !important;
}
.deo-cover:hover {
  cursor: pointer;
}
.deo-cover--hidden {
  display: none;
}
.deo-cover-button {
  width: 90px;
  height: 72px;
  border-radius: 7.2px;
  background: black;
  opacity: 0.7;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.deo-cover:hover .deo-cover-button {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.deo-cover-play-icon {
  width: 40;
  height: 40px;
  border-style: solid;
  border-width: 20px 0 20px 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-color: transparent transparent transparent white;
  -webkit-transform: scale(0.5, 0.7) translateX(5px);
  -ms-transform: scale(0.5, 0.7) translateX(5px);
  transform: scale(0.5, 0.7) translateX(5px);
}
.deo-loading {
  z-index: 90;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
}
.deo-loading--hidden {
  display: none;
}
.deo-loading-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  border-color: #39bae5 #39bae5 #39bae5 transparent;
  -webkit-animation: loading 1s linear infinite;
  animation: loading 1s linear infinite;
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.deo-page {
  z-index: 80;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: visibility 0s, opacity 0.2s ease-out;
  -o-transition: visibility 0s, opacity 0.2s ease-out;
  transition: visibility 0s, opacity 0.2s ease-out;
  visibility: hidden;
}
.deo-page > * {
  pointer-events: none;
}
.deo-page--active,
.deo-page--force-visible {
  opacity: 1;
  visibility: visible;
}
.deo-page--active > *,
.deo-page--force-visible > * {
  pointer-events: all;
}
.deo-close-button {
  position: absolute;
  display: none;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  pointer-events: all;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}
.deo-close-button img {
  margin: 8px;
}
.deo-controls--hidden .deo-close-button {
  -webkit-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
}
.deo-vr-button {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 95px;
  height: 40px;
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  pointer-events: all;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}
.deo-vr-button img {
  margin-right: 7px;
}
.deo-vr-button span {
  line-height: 0;
}
.deo-controls--hidden .deo-vr-button {
  -webkit-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
}
.deo-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 90;
  display: none;
}
.deo-message a,
.deo-message a:hover,
.deo-message a:active {
  color: white;
}
.deo-message--visible {
  display: block;
}
.deo-message-content-flex-v {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.deo-message-close-button {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
}
.deo-message-bounds {
  padding: 50px 20px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.deo-message-bounds-even {
  padding: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.deo-message-tab-headers {
  margin-bottom: 5px;
}
.deo-message-tab-header {
  display: inline;
  font-size: 1em;
  padding: 5px 4px;
  margin: 0 5px;
  font-weight: 700;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  border-bottom: 3px solid;
  border-color: transparent;
}
.deo-message-tab-header--selected {
  border-color: white;
}
.deo-message-tab-header:hover {
  border-color: #39bae5;
}
.deo-message-tab-page {
  line-height: 1.5em;
}
.deo-message-tab-page--hidden {
  display: none;
}
.deo-message-sm,
.deo-message-lg {
  width: 100%;
  height: 100%;
}
.deo-message-sm {
  display: none;
}
.deo-get-app h2 {
  font-size: 1.5em;
  line-height: 1.3em;
}
.deo-get-app h2 img {
  height: 20px;
  margin-bottom: -3px;
  margin-right: 3px;
}
.deo-get-app a.button {
  background: #656565;
  font-size: 1.2em;
  padding: 10px 13px;
  border-radius: 2px;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
  margin: 8px 5px 0px 0;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.deo-get-app a.button:hover {
  background: #39bae5;
}
.deo-get-app-store-links {
  padding: 15px 0;
  margin-top: -8px;
}
.deo-get-app #get-app-tab-pages {
  margin-top: 20px;
}
.deo-learn-more {
  line-height: 1.5;
}
.deo-learn-more h2 {
  font-size: 1.5em;
  margin: 0;
  padding: 0;
  line-height: 1.25;
}
.deo-learn-more h3 {
  margin: 0;
  padding: 0;
  margin-top: 0.3em;
}
.deo-learn-more ul,
.deo-learn-more li {
  display: block;
}
.deo-learn-more .qr-code {
  margin: 10px 0 20px;
  max-width: 150px;
}
.deo-learn-more-flags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.deo-learn-more-flags-headers {
  padding-right: 20px;
  width: 60%;
}
.deo-learn-more-flags-list {
  width: 40%;
}
.deo-learn-more-flag-item {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  display: inline-block;
  margin-top: 5px;
  padding: 5px 8px;
  position: relative;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.deo-learn-more-flag-item:hover {
  background: rgba(255, 255, 255, 0.4);
}
.deo-learn-more-flag-item.copied .copied-message {
  -webkit-animation: copied 1s alternate 1 ease-out;
  animation: copied 1s alternate 1 ease-out;
}
.deo-learn-more-flag-item .copied-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding-top: 6px;
  opacity: 0;
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
}
@-webkit-keyframes copied {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes copied {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.deo-learn-more-deeplinks-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5em;
}
.deo-learn-more-deeplinks-list {
  padding-top: 1.1em;
}
.deo-learn-more-deeplink {
  display: inline-block;
  background: #3e3e3e;
  color: rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 12px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.deo-learn-more-deeplink img {
  width: 24px;
  margin-right: 5px;
  margin-bottom: -5px;
}
.deo-learn-more-deeplink:hover {
  background: #757575;
}
.deo-learn-more-description li {
  margin-top: 0.5em;
}
.deo-learn-more-description li::before {
  content: "—";
  margin-right: 5px;
}
.deo-learn-more-explainer {
  margin-top: 1.5em;
}
.deo-learn-more-novr {
  display: none;
  font-size: 1.5em;
  padding: 0.75em 0;
}
.deo-learn-more--no-chrome .deo-learn-more-novr {
  display: block;
}
.deo-learn-more--no-chrome .deo-learn-more-flags {
  display: none;
}
.deo-learn-more-continue {
  display: none;
  background: #3e3e3e;
  padding: 10px 15px;
  margin-top: 1em;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.deo-learn-more-continue:hover {
  background: #39bae5;
}
.deo-error {
  background: rgba(25, 0, 0, 0.9);
}
.deo-error-text {
  font-size: 1.2em;
}
.deo-default-preplay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 22px 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    color-stop(32%, rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 32%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 32%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}
.deo-default-preplay * {
  pointer-events: none;
}
.deo-default-preplay-title {
  margin-bottom: 4px;
}
.deo-default-preplay-title-left {
  font-weight: 500;
  float: left;
  max-width: 80%;
  font-size: 18px;
}
.deo-default-preplay-title-right {
  float: right;
  font-weight: 500;
  margin-top: 5px;
}
.deo-default-preplay-subtitle {
  font-size: 14px;
}
.deo-deeplink {
  position: absolute;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  padding: 12px 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  max-width: 40px;
  overflow: hidden;
  word-wrap: none;
  white-space: nowrap;
  font-weight: 700;
}
.deo-deeplink.slr-style {
  background: #3e3e3e;
  color: rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.deo-deeplink.slr-style:hover {
  background: #757575;
}
.deo-deeplink:hover,
.deo-deeplink.open {
  max-width: 200px;
}
.deo-deeplink img {
  width: 20px;
  margin-bottom: -3px;
  margin-right: 8px;
}
.deo-controls--hidden .deo-deeplink {
  -webkit-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
}
@media (max-width: 540px) {
  .deo-close-button {
    top: 8px;
    right: 8px;
    display: block;
  }
  .deo-vr-button {

    top: 8px;
    left: 8px;
  }
  .deo-deeplink {
    top: 8px !important;
    right: 58px !important;
  }
  .deo-learn-more > div {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    padding: 1.5em;
  }
  .deo-learn-more-flags {
    display: block;
  }
  .deo-learn-more-flags-headers,
  .deo-learn-more-flags-list {
    width: 100%;
  }
  .deo-learn-more-deeplinks-container {
    display: block;
  }
  .deo-learn-more-flags-list {
    margin-top: 1.5em;
  }
  .deo-learn-more-description ul {
    padding-left: 0;
  }
  .deo-learn-more-continue {
    display: block;
  }
  -sm {
    display: block;
  }
  -lg {
    display: none;
  }
}
.deo-player-replay {
  display: none;
  z-index: 90;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.deo-player-replay--visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.deo-player-replay-button {
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  border-radius: 4px;
  width: 90px;
  height: 72px;
  background-color: rgba(0, 0, 0, 0.65);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.deo-player-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.deo-controls--hidden .deo-player-controls {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
.deo-player-seekbar-container {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: all;
  cursor: pointer;
}
.deo-player-seekbar-container:hover .deo-player-preview {
  opacity: 1 !important;
}
.deo-player-seekbar {
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 19px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.deo-player-preview {
  width: 80px;
  height: 45px;
  background: #222;
  position: absolute;
  bottom: 60px;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}
.deo-player-preview-spinner {
  display: none;
  border: 2px solid;
  border-color: orange #444 #444 #444;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.deo-player-preview--loading {
  background: #222 !important;
}
.deo-player-preview--loading .deo-player-preview-spinner {
  display: block;
}
.deo-player-preview--visible {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.deo-player-seekbar-progress {
  background: #555;
  height: 2px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.deo-player-seekbar-filler {
  width: 100%;
  height: 100%;
  background: #39bae5;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.deo-player-seekbar-handle-container {
  pointer-events: none;
  width: 100%;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.deo-player-seekbar-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #39bae5;
  margin-top: -6px;
  right: -10px;
  position: absolute;
  -webkit-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.deo-player-seekbar-container:hover .deo-player-seekbar-handle {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}
.deo-player-ctrls-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.deo-player-ctrls-right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}
.deo-player-hub-button {
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 16px;
  left: 124px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.deo-player-hub-button img {
  width: 24px;
  height: 24px;
  margin: 8px 8px;
  fill: white;
}
.deo-controls--hidden .deo-player-hub-button {
  -webkit-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
}
.deo-player-timecode {
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  height: 40px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 82px;
  flex: 0 0 82px;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  pointer-events: all;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 0;
  padding-top: 20px;
  font-size: 12px;
}
.deo-player-button {
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  width: 40px;
  height: 40px;
  float: left;
  shape-rendering: crispEdges;
  margin: 0 8px 16px 8px;
  pointer-events: all;
}
.deo-player-button-icon {
  width: 24px;
  height: 24px;
  margin: 8px 8px;
  fill: white;
}
.deo-player-button:focus {
  outline: 0;
}
.deo-player-button:first-child {
  margin-left: 16px;
}
.deo-player-button:last-child {
  margin-right: 16px;
}
.deo-player-opts {
  height: 40px;
  width: 40px;
  background: none !important;
  position: relative;
}
.deo-player-opts--visible .deo-player-opts-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.deo-player-opts-container {
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.deo-player-opts-button {
  border-radius: 4px;
  border: 1px solid transparent;
  width: 100%;
  height: 40px;
  bottom: 0;
  position: absolute;
  line-height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 7px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.deo-player-opts-button span {
  margin-left: 3px;
}
.deo-player-opts-button img {
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  margin-top: 1px;
}
.deo-player-opts-expand {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  bottom: 40px;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 7px 0;
  background: inherit;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  border-radius: 4px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.deo-player-quality-button {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 7px 14px;
  color: white;
  cursor: pointer;
}
.deo-player-quality-button:hover {
  background: rgba(25, 25, 25, 0.65);
}
.deo-player-quality-button.is-selected {
  background: #39bae5;
  color: black;
  display: none;
}
.deo-player-volume {
  background: none;
  position: relative;
}
.deo-player-volume-bar {
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  -webkit-transition: all 0.2s ease-out 0.5s;
  -o-transition: all 0.2s ease-out 0.5s;
  transition: all 0.2s ease-out 0.5s;
  overflow: hidden;
}
.deo-player-volume-bar:hover {
  height: 183px;
  -webkit-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
  transition-delay: 0s !important;
}
.deo-player-mute {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
}
.deo-player-vol-slider-outer {
  position: absolute;
  bottom: 40px;
  height: calc(100% - 40px);
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 16px 19px;
}
.deo-player-vol-slider-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #555;
}
.deo-player-vol-filler {
  background: #39bae5;
  width: 100%;
  height: 100%;
}
.deo-player-options {
  z-index: 200;
  width: 150px;
  height: 100%;
  position: absolute;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0)),
    color-stop(3%, rgba(0, 0, 0, 0)),
    color-stop(21%, rgba(0, 0, 0, 0.11)),
    to(rgba(0, 0, 0, 0.65))
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 3%,
    rgba(0, 0, 0, 0.11) 21%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 3%,
    rgba(0, 0, 0, 0.11) 21%,
    rgba(0, 0, 0, 0.65) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
  background: #292929;
  right: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  color: white;
}
.deo-player-options-logo {
  line-height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  padding: 12px 15px;
  margin: 0px auto 10px;
  background: #292929 ;
  width: 120px;
  border-radius: 20px;
  -webkit-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
}
.deo-player-options-logo a {
  color: white;
  text-decoration: underline;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}
.deo-player-options-block {
  margin: 0 auto 20px;
  width: 100%;
}
.deo-player-options-block--hidden,
.deo-player-options-block--disabled {
  display: none !important;
}
.deo-player-options-block:hover h3 {
  color: white;
}
.deo-player-options::-webkit-scrollbar {
  display: none;
}
.deo-player-options--visible {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.deo-player-options h3 {
  -webkit-transition: color 0.1s ease-out;
  -o-transition: color 0.1s ease-out;
  transition: color 0.1s ease-out;
  font-weight: normal;
  margin-bottom: 4px;
  color: white;
  font-size: 12px;
}
.deo-player-options-selector-y {
  width: 100%;
}
.deo-player-options-selector-x {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.deo-player-options-selector-2-col {
  width: 100%;
  margin-bottom: 10px;
}
.deo-player-options-selector-2-col input {
  width: 50% !important;
  float: left !important;
}
.deo-player-options-selector-2-col input:first-child:nth-last-child(1) {
  width: 100% !important;
}
.deo-player-options-selector-x,
.deo-player-options-selector-y,
.deo-player-options-selector-2-col {
  overflow: hidden;
  border-radius: 4px;
  border: 0px solid white;
  background: rgba(0, 0, 0, 0.25);
}
.deo-player-options-selector-x input,
.deo-player-options-selector-y input,
.deo-player-options-selector-2-col input {
  -webkit-appearance: none;
  width: 100%;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 7px 10px;
  display: block;
  background: none;
  cursor: pointer;
  outline: none;
  color: white;
  margin: 0;
  -webkit-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}
.deo-player-options-selector-x input.selected,
.deo-player-options-selector-y input.selected,
.deo-player-options-selector-2-col input.selected {
  background: rgba(255, 255, 255, 0.8);
  color: #0a7599;
}
.deo-player-options-selector-x input.selected:hover,
.deo-player-options-selector-y input.selected:hover,
.deo-player-options-selector-2-col input.selected:hover {
  background: white;
}
.deo-player-options-selector-x input:hover,
.deo-player-options-selector-y input:hover,
.deo-player-options-selector-2-col input:hover {
  background: rgba(255, 255, 255, 0.2);
}
.deo-player .deo-deeplink {
  display: none;
  top: 16px;
  right: 16px;
}
@media (max-width: 540px) {
  .deo-player-seekbar-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding: 0 8px;
    margin-bottom: 8px;
  }
  .deo-player-seekbar {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .deo-player-preview {
    display: none;
  }
  .deo-player-ctrls-left {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .deo-player-ctrls-right {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    margin-right: 4px;
  }
  .deo-player-hub-button {
    top: 8px;
    left: 111px;
  }
  .deo-player-button {
    margin: 0 4px 8px 4px;
  }
  .deo-player-button:first-child {
    margin-left: 8px;
  }
  .deo-player-button:last-child {
    margin-right: 8px;
  }
  .deo-player-volume-bar:hover {
    height: 100%;
  }
  .deo-player-fullscreen {
    display: none;
  }
  .deo-player-timecode {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-left: auto;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    text-align: center;
  }
  .deo-player-options {
    width: 240px;
  }
  .deo-player-options h3 {
    font-size: 14px;
  }
  .deo-player-options-selector-x input,
  .deo-player-options-selector-y input,
  .deo-player-options-selector-2-col input {
    padding: 10px 14px;
    font-size: 14px;
  }
}
.deo-hub-fullscreen {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  -o-transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  -webkit-backdrop-filter: blur(16.8px);
  backdrop-filter: blur(16.8px);
  background-color: rgba(0, 0, 0, 0.65);
}
.deo-hub-fullscreen img {
  margin: 8px;
}
@media (max-width: 540px) {
  .deo-hub-fullscreen {
    bottom: 8px;
    right: 8px;
  }
}
.deo-player-controls-ad-container {
  position: absolute;
  bottom: 64px;
  left: 16px;
  right: 16px;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  pointer-events: none !important;
}
.deo-player-controls-ad {
  background: white;
  color: black;
  width: 256px;
  border-radius: 4px;
  padding: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.5em;
  cursor: pointer;
  pointer-events: all;
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}
.deo-player-controls-ad:hover {
  background: #f5f1dc;
}
.deo-player-controls-ad-textbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 8px;
}
.deo-player-controls-ad-textbox h2 {
  margin: 0;
  font-size: 1em;
}
.deo-player-controls-ad-img {
  max-height: 56px;
}
.deo-player-ads-hidden .deo-player-controls-ad-container {
  opacity: 0;
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
  transition-delay: 0s !important;
}
.deo-player-ads-hidden .deo-player-controls-ad {
  pointer-events: none !important;
}
@media (max-width: 540px) {
  .deo-player-controls-ad-container {
    bottom: 104px;
    left: 8px;
    right: 8px;
  }
}
