// COLORS
$primary-color: #77F73B;
$secondary-color: #ffd800;
$third-color: #ED2779;
$black-color: #000000;

//LINKS
$link-hover-color: #77F73B;
$link-color: #77F73B;

// BACKGROUND
$bg-third: #060F43;

// TEXT
$typography-color: #fff;
$typography-dark-color: rgb(13, 12, 39);
$typography-header-color: $primary-color;

