* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    :focus-visible {
        outline: none;
    }
}

a {
    cursor: pointer;
    text-decoration: none !important;
    transition: all $transition linear;
}

.img {
    width: 100%;
}

a:hover {
    color: $link-hover-color !important;
    text-decoration: none !important;
    p {
        text-decoration: none !important;
    }

    filter: brightness(1.1);
}

ul {
    list-style-type: none;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.flex {
    display: flex;
}

.flex.column {
    flex-direction: column;
}

.flex.center {
    align-items: center;
    justify-content: center;
}

.flex.items-center {
    align-items: center;
}

.flex.content-center {
    justify-content: center;
}

.typography {
    font-family: $font-medium;
    font-size: $font-size-md;
    color: $typography-color;
}

.typography.h {
    font-family: $font-bold !important;
    font-size: $font-size-lg;
    color: $typography-header-color !important;
}

.typography.sm {
    font-size: 12px !important;
}

.typography.md {
    font-size: 14px !important;
}

.typography.lg {
    font-size: 16px !important;
}

.typography.xlg {
    font-size: 30px !important;
}

.typography.primary {
    color: $primary-color !important;
}

.typography.xxxlg {
    font-size: 60px !important;
    line-height: 1.2;
}

.font.EffraRegular {
    font-family: 'Effra Light' !important;
}

.font.EffraMedium {
    font-family: 'Effra Medium' !important;
}

.font.EffraBold {
    font-family: 'Effra Bold' !important;
}

.font.Gilroy {
    font-family: $font-gilroy-regular !important;
}

.font.GilroyMedium {
    font-family: $font-gilroy-medium !important;
}

.font.GilroyBold {
    font-family: $font-gilroy-bold !important;
}

.font.GilroyBlack {
    font-family: $font-gilroy-black !important;
}

.font.Montserrat {
    font-family: 'montserratRegular' !important;
}


.text-center {
    text-align: center;
}

.capitalize {
    text-transform: capitalize;
}

.link {
    text-decoration: underline !important;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
        text-decoration: none !important;
    }
}

.disableFilter:hover {
    filter: unset !important;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #121212;
    border: 2px solid #000;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    padding: 32px;
}

.close {
    z-index: 1000;
    color: $typography-color !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: unset !important;
    right: 1%;
    top: 1.5%;
    position: fixed !important;
}