.btn {
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border: 1px solid transparent !important;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.btn:hover {
    filter: brightness(1.05);
}

.btn-purple {
    background: #A725F9 !important;
    color: white !important;
    transition: 0.2s all ease-out !important;
}

.btn-modal {
    width: fit-content;
    position: fixed !important;
    z-index: 15;
    color: white !important;
}

.btn-gradient {
    background: linear-gradient(90deg, rgba(83, 255, 52, 1) 0%, rgba(244, 255, 67, 1) 35%, rgba(238, 199, 139, 1) 63%, rgba(206, 116, 116, 1) 82%, rgba(255, 0, 138, 1) 100%);
    color: black !important;
    text-transform: capitalize !important;
}

.btn-bordered {
    border: 2px solid #707070 !important;
}

.btn-purple:hover {
    filter: brightness(0.85) !important;
    box-shadow: inset wheat 0px 0px 2px !important;
}

.btn-white-text {
    color: white;
    background: unset;
}

.btn-yellow {
    background-color: black !important;
    color: yellow !important;
    border-radius: 5px !important;
    width: fit-content !important;
}

.btn-yellow-reversed {
    background-color: #ffe600 !important;
    color: black !important;
    border-radius: 5px !important;
    width: fit-content !important;
}

.btn-underlined {
    position: relative;
}

.btn-underlined:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: currentColor;
    transform-origin: bottom right;
    transition: transform 0.15s ease-out;
}

.btn-underlined:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.btn-chat {
    width: 50% !important;
    padding: 11px 16px !important;
    border-bottom-left-radius: 0px !important;
    background: #000000 !important;
    color: white !important;
    border-right: 0 !important;
    border-bottom: 3px solid transparent !important;
    border-radius: 0 !important;
    position: relative !important;
    transition: all 0.2s ease-out;
}

.btn-chat:hover {
    background: #1f1f1f !important;
    border-bottom: 3px solid #1a360d !important;
}

.btn-chat.active {
    background: #77F73B !important;
    border-bottom: 3px solid #77F73B !important;
    color: black !important
}

.btn-grey {
    background-color: #1D1D1D !important;
    width: 104px;
    height: 49px;
    font-size: 12px !important;
    color: white !important;
    border-radius: 5px !important;
}

.btn:hover {
    border: 1px solid transparent !important;
    filter: brightness(1.20);
}

.btn-primary {
    background-color: var(--primary-color) !important;
}

.btn-third {
    background-color: var(--primary-color) !important;
    color: black !important;
    padding: 8px 16px !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
}

.btn-disabled {
    background-color: rgb(150, 150, 150) !important;
}

.btn-fourth {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    padding: 8px 16px !important;
    border-radius: 5px !important;
    border: 1px solid var(--primary-color) !important;
}

.btn-fourth:hover {
    background-color: rgb(80 142 95 / 15%) !important;
    border: 1px solid var(--primary-color) !important;
}

.buttonPanel {
    background: #292929 !important;
    border-radius: 50% !important;
    min-width: unset !important;
    color: white !important;
    width: 44px;
    height: 44px;
}

.buttonPanel.active {
    color: var(--primary-color) !important;
}

.badge-panel .MuiBadge-badge {
    border-radius: 50% !important;
    height: 28px !important;
    width: 28px !important;
    border: 3px solid RGB(25, 25, 25);
    top: -1px !important;
    right: -6px !important;
    line-height: 2 !important;
}

.btn-magic:hover {
    transition: all 0.7s !important;
    background: linear-gradient(-45deg, #fe3dff, #ff3d9a, #ff3d3d, #ffae3d, #a5ff3d, #3dffbc, #3d86ff, #fe3dff, #ff3d9a);
    background-size: 1200% !important;
    animation: anime 4s linear infinite !important;
    -moz-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5) !important;
    -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5) !important;
    -o-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5) !important;
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5) !important;
}

.btn-primary:active {
    box-shadow: inset 0 0 4px rgb(0 0 0) !important;
    filter: sepia(0.5);
}

@keyframes anime {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 50% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

.btn-primary * {
    color: var(--black-color) !important;
}


.btn-secondary {
    border: 1px solid transparent !important;
}



.btn-disabled {
    pointer-events: none;
}

.btn-skip {
    background-color: black !important;
    color: white !important;
    border: 1px solid white !important;
    padding: 6px 18px !important;

    &:hover {
        border: 1px solid rgb(204, 204, 204) !important;
        background-color: rgb(51, 51, 51) !important;
    }
}

.notificationBtn {
    width: 100%;
    background-color: rgba(0, 0, 0, .95) !important;
    border-radius: unset !important;
    transition: all .4s;
    justify-content: space-between !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 11px !important;
    color: #4da723 !important;
}

.notificationBtn svg {
    width: 0.9em !important;
}

.notificationBtn:hover {
    background-color: #222921 !important;
    color: #60d32b !important;
}