@media screen and (min-width: 1900px) {
  .LiveStreamScreen .video_container {
    height: fit-content;
    min-height: 635px;
  }
}

@media screen and (max-width: 1570px) {
  .chat-text-info {
    display: none !important;
  }
  .about_btns_container {
    > button > span:nth-child(1) {
      font-size: 1.8rem !important;
    }
    > button > span:nth-child(2) {
    }
  }
}

@media screen and (max-width: 1350px) {
  .flex-mobile {
    align-items: flex-start !important;
    flex-wrap: wrap;
    justify-content: center !important;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  .about_btns_container {
    > button > span:nth-child(1) {
      font-size: 1.6rem !important;
    }
    > button > span:nth-child(2) {
    }
  }

  .actions-container {
    margin-top: 35px !important;
  }

  .lush-container {
    margin-top: 35px !important;
    align-items: flex-start !important;
  }

  .lush-margin {
    margin-left: 50px !important;
  }

  .no-margin {
    margin-left: unset !important;
  }
}

@media screen and (max-width: 1780px) {
}

@media screen and (max-width: 1650px) {
  .profileBackground {
    background-size: 55%, cover, cover !important;
    background-position-x: -50px, 30%, 0% !important;
  }
  .housemateProfileButtons {
    flex-direction: column !important;
    button {
      width: 100%;
      justify-content: unset !important;
      padding-left: 24px !important;
    }
  }
  .housemateProfileSocials {
    flex-direction: column !important;
    button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1450px) {
  .profileBackground {
    background-size: 62%, cover, cover !important;
    background-position-x: -100px, 30%, 0% !important;
  }
}

@media screen and (max-width: 1350px) {
  .profileBackground {
    background-size: 68%, cover, cover !important;
    background-position-x: -100px, 30%, 0% !important;
  }
}

@media screen and (max-width: 1250px) {
  .profileBackground {
    background-size: 75%, cover, cover !important;
    background-position-x: -150px, 30%, 0% !important;
  }
}

@media screen and (max-width: 1150px) {
  .profileBackground {
    background-size: 80%, cover, cover !important;
    background-position-x: -150px, 30%, 0% !important;
  }
}

@media screen and (max-width: 1050px) {
  .profileBackground {
    background-size: 85%, cover, cover !important;
    background-position-x: -150px, 30%, 0% !important;
  }
}

@media screen and (max-width: 950px) {
  .profileBackground {
    background-size: 90%, cover, cover !important;
    background-position-x: -150px, 30%, 0% !important;
  }
}

@media screen and (max-width: 900px) {
  .profileBackground {
    background-size: 93%, cover, cover !important;
    background-position-x: -150px, 30%, 0% !important;
  }
  .i_frame {
    text-align: center !important;
    margin: 0 auto !important;
    background-image: unset !important;
    /*  padding: 20px 15px; */
    background: #131313 0% 0% no-repeat padding-box !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    -moz-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    border-radius: 10px !important;
    opacity: 1 !important;
    border: unset;
    max-width: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: unset;
  }
}

@media screen and (max-width: 900px) {
  .cam-card {
    width: 100% !important;
    height: unset !important;
  }

  .progress-primary {
    background: #1e1e1e !important;

  }
  .Footer {
    .logos {
      height: unset !important;
      gap: 0 50px !important;
      flex-wrap: wrap;
      padding: 0 10px;
    }
  }

  .about_btns_container {
    align-items: center;
    justify-content: center;
    > button {
      width: 48%;
      height: 61px;
    }
    > button > span:nth-child(1) {
      font-size: 0.9rem !important;
    }
    > button > span:nth-child(2) {
      font-size: 0.8rem !important;
    }
  }

  .chatInput_container_private > div {
    background-color: unset !important;
  }

  .ImgPlace .img {
    width: 35px !important;
    height: 35px !important;
    border-radius: 50% !important;
  }

  .lush-pulse g {
    clip-path: none;
  }

  .post_container {
    padding: 40px 0 !important;
  }

  .post_wrap {
    padding-left: 40px;
    padding-right: 40px;
  }

  .post_sidebar {
    padding-left: 25px;
    padding-right: 5px;
    max-height: 485px !important;
    overflow-y: scroll !important;
  }

  .post_sidebar_wrap {
    padding-left: unset !important;
    margin-top: 80px !important;
  }

  .post_sidebar a {
    width: 100%;
    display: flex;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.07);
  }

  .post_sidebar h4 {
    font-size: 16px;
    width: 100%;
    padding-left: 10px;
  }

  .post_sidebar a:hover {
    background-color: #282828;
  }

  .post_sidebar a:active {
    background-color: #282828;
  }

  .post_sidebar .img-wrap {
    margin: 0 !important;
  }

  .post_sidebar img {
    width: 25vh;
    height: 15vh;
    object-fit: cover;
    border-radius: 10px;
  }

  .hide-mobile {
    display: none !important;
  }

  .blog .MuiMasonry-root {
    align-content: center !important;
    align-items: center !important;
    margin: 0px !important;
  }

  .blog .MuiPaper-root {
    width: calc(50% - 15px) !important;
    margin: 8px !important;
  }

  #profile .MuiGrid-item {
    width: 100% !important;
    max-width: 100% !important;
  }

  #profile .MuiTableCell-root span {
    height: unset !important;
    line-height: 1.4 !important;
  }

  .profileBackground_gradient {
    height: 64vh !important;
    z-index: 2 !important;
  }

  .profileBackground {
    background-size: 58vh, cover, cover !important;
    background-position-x: center, 30%, 0% !important;
    height: 64vh !important;
    z-index: 2;
  }

  .ModelProfilePage_main > .ModelButtonsStack > button {
    max-height: 50px !important;
    width: 220px !important;
    min-width: 220px !important;
  }

  #tableHeader {
    font-size: 22px !important;
    margin-top: 30px !important;
    margin-bottom: 5px !important;
  }

  .ModelProfilePage_wrap {
    display: flex;
    padding-bottom: 10px;
    flex-direction: column !important;
    flex-wrap: wrap;
  }

  .ModelProfilePage_main {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
  }

  .ModelProfilePage_description {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    flex-direction: column;
    align-content: center;
  }

  .ModelProfilePage_row .content {
    color: #ffffff;
    min-width: 180px;
    max-width: 420px;
    /* padding: 0px !important; */
    /* line-height: 1.3 !important; */
    font-size: 16px !important;
    padding-top: 4px !important;
    font-weight: normal;
    line-height: 1;
    margin-left: 13px;
    padding-top: 0px !important;
    /* margin-top: 1px; */
  }

  .ModelProfilePage_main:nth-child(2) {
    margin-bottom: 25px !important;
  }

  #profile tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.75);
  }

  #profile tr:nth-of-type(odd) {
    background-color: #292929 !important;
  }

  #profile tr {
    display: flex;
    flex-direction: column;
  }

  #profile th {
    width: 100% !important;
    padding: 10px 20px 0px 45px;
    display: flex;
    align-items: center;
    position: relative;
  }

  #profile td {
    width: 100% !important;
    padding: 0px 20px 15px 56px;
    display: flex;
    min-height: 33px;
    height: unset;
    align-items: center;
  }

  #profile th img {
    position: absolute;
    left: 17px;
    top: 15px;
  }

  #profile table {
    min-width: unset !important;
  }

  .ChatList {
    padding: 0;
  }

  .ChatList .ChatListElem {
    margin-top: unset !important;
    padding: 10px 20px !important;
  }

  .ChatListElem_wrap {
    margin-top: 2px !important;
  }

  .ChatListElem_wrap .msg {
    margin: 0px 8px 3px 8px !important;
  }

  .onlineCard {
    width: 55px !important;
    height: 55px !important;
  }

  .headerAvatar {
    width: 50px !important;
    height: 50px !important;
  }

  .compaign_container {
    margin: 20px auto !important;
  }

  .highlights {
    height: auto !important;
  }

  .modelProfilePageBioImage_wrap {
    flex-direction: column !important;
    align-items: center !important;
  }

  .modelProfilePageAnswers_wrap {
    flex-direction: column !important;
  }

  .ModelProfileImage {
    justify-content: center !important;
  }

  .ChatWrapper .react-emoji {
    flex-direction: row;
  }
  .ChatWrapper .react-input-emoji--button {
    position: absolute;
  }

  .ChatWrapper_Main .react-emoji {
    padding-left: 0px;
    width: 80%;
  }

  .ChatWrapper .react-input-emoji--input {
    max-height: 54px !important;
    overflow-y: hidden;
    overflow-x: auto;
    margin-left: 50px;
    padding: 9px 12px 11px 0px;
    width: 100%;
  }
  .ChatWrapper .react-input-emoji--container {
    border-radius: 5px !important;
  }
  .ChatWrapper .react-input-emoji--placeholder {
    left: 50px;
  }

  .ChatWrapper_Main .react-input-emoji--input {
    max-height: 40px !important;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .ChatWrapper_Main .react-input-emoji--container {
    margin: 5px 0px !important;
  }

  .ChatWrapper_Main
    .react-emoji
    > .react-emoji-picker--container
    > .react-emoji-picker--wrapper {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    bottom: unset !important;
  }

  .ChatWrapper_Main .react-emoji > .react-emoji-picker--container {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    top: 50px;
  }

  .chatInput_container_main {
    top: 0 !important;
  }
  #ChatInput {
    border-radius: 10px !important;
  }
  .ChatInput {
    input {
      width: 80% !important;
      padding: 7px 10px 7px 10px !important;
    }
  }
  aside.EmojiPickerReact.epr-main {
    bottom: 0 !important;
    top: 53px;
  }
  .private aside.EmojiPickerReact.epr-main {
    bottom: 53px !important;
    top: unset;
  }
}

@media screen and (max-width: 620px) {
  .box-support {
    width: 100% !important;
    min-width: unset !important;
    margin: 70px auto 230px !important;
  }

  .blog .MuiMasonry-root {
    height: auto;
  }

  .blog .MuiPaper-root {
    width: calc(100% - 15px) !important;
    margin-bottom: 30px !important;
  }

  .MediaProfile_container {
    width: 93% !important;
  }

  .inputBoxProfile {
    width: 100% !important;
  }

  .ModelProfilePage_row {
    width: 95% !important;
  }

  .checkProfile {
    display: none !important;
  }
}

@media screen and (max-width: 540px) {
  .lovense-tooltip {
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .MuiTooltip-tooltip {
      margin-right: 80px;
    }
  }

  .CreditsStatusPanel_grid .notifications {
    display: none !important;
  }

  .buttonPanel {
    width: 40px !important;
    height: 40px !important;
  }

  .StatusPanel_Container > div {
    padding-left: unset !important;
    padding-right: unset !important;
  }

  .CreditsStatusPanel_grid.mobile {
    justify-content: space-evenly !important;
    align-items: flex-end !important;
  }
}

@media only screen and (min-width: 900px) {
  .notifications-model {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .private-chat-text-info {
    top: -30px !important;
  }

  .chat-text-info {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    padding: 6px 12px;
    display: flex !important;
    position: absolute !important;
    top: -40px !important;
    left: 0px !important;
    right: unset !important;
  }

  .chat-text-info_main {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    padding: 6px 12px;
    display: flex !important;
    position: absolute !important;
    top: 55px !important;
    right: 0px !important;
  }

  .tipsBlock.mobile {
    background-color: transparent !important;
  }

  .btn-skip {
    bottom: 80px !important;
    padding: 4px 14px !important;
  }
}

@media only screen and (max-width: 900px) and (min-width: 480px) {
  .avatarGrid > div:nth-child(1) {
    max-width: 100%;
    flex-basis: 50%;
  }

  .avatarGrid > div:nth-child(2) {
    max-width: 100%;
    flex-basis: 50%;
  }

  .setAvatarElem .MuiTypography-root {
    width: unset !important;
    padding: 0 5px;
  }

  .setAvatarElem .MuiAvatar-root {
    width: 60px;
    height: 90px;
  }

  .avatarBox {
    max-width: 360px !important;
    min-width: unset !important;
  }

  .avatarBox2 {
    max-width: 660px !important;
    min-width: unset !important;
  }
}

@media screen and (max-width: 450px) {
  .screen-text.mobile {
    max-width: 200px;
    margin-top: 12px;
    text-align: center;
  }

  .screen-text.mobile svg {
    width: 0.7em;
    height: 0.7em;
    margin-bottom: 4px;
  }

  .screen-text.mobile button {
    padding: 5px 11px !important;
    font-size: 13px !important;
  }

  .header-container {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .headerAvatar {
    width: 45px !important;
    height: 45px !important;
  }

  .JoinPanelContainer {
    /* display: none !important; */
  }

  .MediaProfile_container .tab-primary {
    width: 50% !important;
  }

  .MediaProfile_container .MuiPaper-root {
    width: 100% !important;
    margin-right: unset !important;
  }

  .ProfileTab {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 415px) {
  .AvatarModal {
    padding: 18px 24px !important;
  }

  .setAvatarElem {
    margin: 0;
    padding: 0;
  }

  .setAvatarElem .MuiAvatar-root {
    width: 70px;
    height: 60px;
  }
}

/* Portrait */

@media screen and (orientation: portrait) {
  /* Portrait styles */
  @media screen and (max-width: 900px) {
    .LiveStreamScreen .ChatList {
      height: 50vh;
    }

    .userAvatar {
      width: 35px !important;
      height: 35px !important;
    }

    .react-input-emoji--input {
      font-size: 13px !important;
    }

    .ChatListElem_wrap .username {
      font-size: 13px !important;
    }

    .ChatListElem_wrap .msg {
      font-size: 13px !important;
    }

    .JoinText {
      display: none !important;
    }

    .ImgPlace .img {
      width: 35px !important;
      height: 35px !important;
      border-radius: 50% !important;
    }

    .ChatList {
      padding-bottom: 20px !important;
    }
  }

  @media screen and (max-width: 450px) {
    .userAvatar {
      width: 30px !important;
      height: 30px !important;
    }
  }
}

/* Landscape */

@media screen and (orientation: landscape) {
  /* Landscape styles */
  @media screen and (max-width: 900px) {
    .video_preview_container {
      padding-left: 64px !important;
      padding-right: 64px !important;
    }

    .video_preview_container > div {
      left: 85px !important;
    }

    .ChatList {
      padding-bottom: 15px !important;
    }

    .ImgPlace .img {
      width: 35px !important;
      height: 35px !important;
      border-radius: 50% !important;
    }

    .JoinText {
      display: none !important;
    }

    .poolProgress {
      flex-direction: column;
      margin: 5px 0 10px !important;

      & > .MuiPaper-root {
        width: 100%;
      }
    }

    .MuiModal-root > .MuiBox-root {
      height: 100vh;
      overflow-y: scroll;
    }
  }
}
